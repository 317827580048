<template>
  <div>
    <b-badge class="font-size-14" :variant="params.data.EsRepse === 1 ? 'success' : 'secondary'">{{ params.data.EsRepse == '1' ? 'SI' : 'NO' }}</b-badge>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: [
  ],
  data() {
    return {
    };
  },
  methods: {
  },
});
</script>

<style lang="scss" scoped>
.btn-icon {
  padding: 0.25rem .5rem;
}
</style>
